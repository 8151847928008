.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  background-color: #202020;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: #202020;
  background: #202020;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #555;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888;
}

::-webkit-scrollbar-corner {
  background: #202020;
}

.homePageImage {
  height: 200px;
  width: auto;
  mask-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.65) 100%
  );
  -webkit-mask-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.65) 100%
  );
}

.typedOut {
  overflow: hidden;
  border-right: 0.1em solid #fb73b5;
  white-space: nowrap;
  width: 0;
  animation: typing 3s forwards;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.centerForm {
  display: "flex";
  align-items: "center";
  justify-content: "center";
}

@media screen and (min-width: 650px) {
  .hideOnDesktop {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .hideOnMobile {
    display: none;
  }

  .typedOutMobile {
    overflow: hidden;
    border-right: 0.1em solid #fb73b5;
    white-space: nowrap;
    width: 0;
    animation: typing 3s forwards;
    white-space: normal;
  }

  .buttonOnMobile {
    font-size: 3vw !important;
  }
}

@keyframes fade-in-right {
  from {
    opacity: 0;
    transform: translateX(-15px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.agent1 {
  opacity: 0;
  animation: fade-in-right ease 0.4s forwards;
}
